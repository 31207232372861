import Glide from '@glidejs/glide';
import 'alpinejs';
import 'parsleyjs';

if (document.querySelector('.events-slider')) {
    new Glide('.events-slider', {
        type: 'carousel',
        animationDuration: 800
    }).mount();
}
